// 
// tables.scss
//

//Table centered (Custom)
.table-centered {
  td {
    vertical-align: middle !important;
  }
}

// Custom table components (Custom)
.table {
  .table-user {
    img {
      height: 30px;
      width: 30px;
    }
  }
  .table-action {
    .action-icon {
      color: $gray-600;
      font-size: 1.2rem;
      display: inline-block;
      padding: 0 3px;

      &:hover {
        color: $gray-700;
      }
    }
  }
}

.C_table {
  width: 670px !important;
  height: auto;
  margin: 0 auto;
  color: #666666;
}

.table th {
  font-weight: normal;
  color: #333333 !important;
}

.C_table td {
  display: table-cell;
  height: 50px;
  line-height: 20px;
  font-size: 14px;
  padding: 5px;
}

.C_tableTitle {
  text-align: center;
  font-size: 25px;
  line-height: 40px;
  color: #333333;
}

.C_center {
  text-align: center;
}

.C_table input[type="text"] {
  height: 100%;
}

.C_table input[type="file"] {
  display: none;
}

.C_table input[type="text"], .C_table textarea {
  display: inline-block;
  width: 100%;
  padding: 5px;
  outline: none;
  margin: 0;
  border: 0;
}

.C_table input[type="radio"] {
  margin: 0 5px 0 0;
  outline: none;
  vertical-align: middle;
}

.C_table label {
  margin: 0 20px 0 0;
  vertical-align: middle;
  font-weight: normal;
}

.C_collector {
  width: 670px;
  display: flex;
  margin: 2px auto;
}

.C_collector > div {
  flex: 1;
  height: 40px;
  line-height: 40px;
}

.C_collector span, .C_collector input {
  display: inline-block !important;
}

.C_collector input {
  width: 150px;
  height: 40px;
  outline: none;
  border: 0;
}

.C_page {
  width: 670px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
}

.C_next {
  float: right;
  cursor: pointer;
}

.C_previous {
  float: left;
  cursor: pointer;
}

.C_table .C_text {
  text-indent: 40px;
  line-height: 35px;
  font-size: 20px;
  display: block;
  text-align: justify;
}

.C_table .C_img {
  height: 200px;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.C_table img {
  cursor: pointer;
}

.C_table .C_img img {
  margin-top: 10px;
  width: auto;
  height: 140px;
  //display: none;
}

.C_table .C_img div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 30px;
}

.C_managementType span {
  display: inline-block;
  line-height: 30px;
}

.C_managementType label, .C_managementType input {
  cursor: pointer;
}

.C_headImg img {
  width: 100%;
  height: 140px;
  cursor: pointer;
}

.allScreen img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

td img {
  display: inline-block;
  width: 100%;
  height: auto;
}

.form-control {
  display: inline-block !important;
}

.C_table1 td > span  {
  display: inline-block;
  width: auto;
  padding: 0;
  margin:0 5px;
  cursor: pointer;
  color: #30376b;
}