//
// forms.scss
//

// Form-control light
.form-control-light {
    background-color: $gray-100;
    border-color: $gray-100;
}


// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}


// Custom select
.custom-select.is-invalid, .form-control.is-invalid,.custom-select:invalid, .form-control:invalid,
.custom-select.is-valid, .form-control.is-valid, .custom-select:valid,.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}
.select2-container--default .select2-selection--multiple .select2-selection__choice{
    max-width: 80px;
    height: 30px;
    line-height: 34px;
    margin-top: 5px!important;
    text-overflow: ellipsis;
  } 
  .select2-container--default .select2-selection--multiple .select2-search--inline{
    margin-top:0px!important;
    padding-left: 10px;
  }
  .select2-container--default .select2-selection--multiple .select2-search--inline .select2-search__field{
      width: 300px!important;
  }
  .select2-container .select2-selection--multiple .select2-selection__rendered{
    margin-bottom: -3px!important;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove{
    margin-left: 0px!important;
    width: auto!important;
    color: #FFFFFF!important;
  }