//
// reboot.scss
//


// Forms
label {
  font-weight: normal;
}


button:focus {
  outline: none;
}